import React from 'react';
import {
   CallToAction,
   FreightServicesGrid,
   Layout,
   PageHeader,
   PageSubtitle,
   Seo,
} from '../../components';
import { graphql } from 'gatsby';

const FreightServices = ({ data }) => {
   const { fserviceItem, freightDescription } = data.datoCmsFreightService;
   const { titleImage } = data;

   return (
      <Layout>
         <Seo
            title='Shipper Services'
            description={`Barcode Buildup Offers various modes and specialized services tailored to your freight needs, from Full Truck Loads (FTL) to Less than Truck Loads (LTL), we strive to be our client's first and last choice with competitive rates, reliable on time service, and safe transport!`}
         />
         <PageHeader
            title='Shipper Services'
            breadCrumbs='services'
            bgImg={titleImage.childImageSharp.gatsbyImageData}
         />
         <PageSubtitle description={freightDescription} structured='true' />
         <FreightServicesGrid list={fserviceItem} />
         <CallToAction
            ctaTitle='Are You Ready to Become a Shipper?'
            center='true'
            btnText='Go to next step'
            linkAddress='https://docs.google.com/forms/d/e/1FAIpQLScRg33EVaEtKiiAMDwt0QziY-V9cJRT_YujWVomxyDCGPuCNw/viewform'
            dark='true'
         />
      </Layout>
   );
};

export default FreightServices;

export const query = graphql`
   {
      titleImage: file(name: { eq: "shippers-header" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
      datoCmsFreightService {
         freightDescription {
            value
         }
         fserviceItem {
            id
            freightItemTitle
            freightImage {
               gatsbyImageData(layout: FULL_WIDTH)
            }
         }
      }
   }
`;
